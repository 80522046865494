<template>
  <div class="s-l-layout__left-side" id="leftside">
    <h2 v-if="me" class="s-h s-h--secondary">Správa rezervací</h2>
    <div v-if="reservations" class="s-table">
      <table class="s-table__table">
        <thead class="s-table__thead">
          <tr class="s-table__tr">
            <th class="s-table__th text-uppercase" scope="col"></th>
            <th class="s-table__th" scope="col">Termín</th>
            <th class="s-table__th" scope="col">Lokalita</th>
            <th class="s-table__th" scope="col">Produkt</th>
            <th class="s-table__th" scope="col">Cena</th>
            <th class="s-table__th" scope="col">Přidružené osoby</th>
            <th class="s-table__th" scope="col" colspan="2">Akce</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reservation, index) in reservations" :key="index" class="s-table__tr" scope="col">
            <th class="s-table__td s-table__td--th" scope="row">{{ index + 1 }}</th>
            <td class="s-table__td">
              <span class="s-table__title"> Termín </span>
              <span class="s-u-fw-bold s-u-fw-md-regular d-block d-md-inline-block">
                {{ reservation.dateTime }}
              </span>
            </td>
            <td class="s-table__td">
              <span class="s-table__title"> Lokalita </span>
              <span class="s-u-fw-bold s-u-fw-md-regular d-block d-md-inline-block"></span>
            </td>
            <td class="s-table__td">
              <span class="s-table__title"> Produkt </span>
              <span class="s-u-fw-bold s-u-fw-md-regular d-block d-md-inline-block"></span>
            </td>
            <td class="s-table__td">
              <span class="s-table__title"> Cena </span>
            </td>
            <td class="s-table__td s-table__td--sm-col-2">
              <span class="s-table__title"> Přidružené osoby </span>
              <div class="s-switch-content d-flex align-items-center justify-content-md-center">
                <span class="s-switch-content__to-hide js-hide"> </span>
                <span class="s-switch-content__to-show js-active"> </span>
                <button
                  class="s-switch-content__btn js-hide"
                  aria-expanded="true"
                  type="button"
                  title="Kdo je přihlášen?"
                >
                  <span class="s-u-visualy-hidden"> Tlačítko pro odkrytí přihlašených lidí </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 3.7 5.3"
                    width="6"
                    height="8.6"
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                  >
                    <path
                      fill="currentColor"
                      d="M1.8 0C1.1 0 .4.3 0 .9c0 .2 0 .3.1.4l.4.3c.1.1.3.1.3 0 .2-.3.5-.5.9-.5.3 0 .7.2.7.5 0 .2-.2.4-.5.5-.3.2-.8.5-.8 1.1v.1c0 .1.1.2.2.2h.8c.1 0 .2-.1.2-.2 0-.4 1.3-.5 1.3-1.7C3.6.7 2.8 0 1.8 0zm-.1 3.9c-.4 0-.7.3-.7.7s.3.7.7.7.7-.3.7-.7c.1-.4-.3-.7-.7-.7z"
                    />
                  </svg>
                </button>
              </div>
            </td>
            <td class="s-table__td s-table__td--action">
              <button type="button" class="s-table__btn">
                <span class="d-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 13.5 12"
                    width="13.5"
                    height="12"
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    class="d-block"
                  >
                    <path
                      fill="#5BBBA1"
                      d="M9.4 1.9L11.5 4c.1.1.1.2 0 .3L6.4 9.5l-2.1.2c-.3.1-.5-.1-.5-.4v-.1L4 7.1 9.1 2c.1-.1.2-.1.3-.1zm3.8-.5L12.1.3c-.4-.4-.9-.4-1.3 0l-.8.8c-.1.1-.1.2 0 .3l2.1 2.1c.1.1.2.1.3 0l.8-.8c.4-.4.4-.9 0-1.3zM9 8.1v2.4H1.5V3h5.4c.1 0 .1 0 .2-.1L8 2c.1-.1.1-.3 0-.4 0-.1-.1-.1-.2-.1H1.1C.5 1.5 0 2 0 2.6v8.3c0 .6.5 1.1 1.1 1.1h8.3c.6 0 1.1-.5 1.1-1.1V7.2c0-.2-.1-.3-.3-.3-.1 0-.1 0-.2.1l-.9.9C9 8 9 8 9 8.1z"
                    />
                  </svg>
                </span>
                <span class="d-block s-table__btn-text" @click="editReservation"> Upravit rezervaci </span>
              </button>
            </td>
            <td class="s-table__td s-table__td--action">
              <button class="s-table__btn s-table__btn--del">
                <span class="d-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 10"
                    width="10"
                    height="10"
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    class="d-block"
                  >
                    <path
                      fill="#AA1B1B"
                      d="M6.9 5l2.8-2.8c.3-.3.3-.9 0-1.3L9.1.3C8.8 0 8.2 0 7.8.3L5 3.1 2.2.3C1.9 0 1.3 0 .9.3L.3.9c-.3.3-.3.9 0 1.3L3.1 5 .3 7.8c-.3.3-.3.9 0 1.3l.6.6c.3.3.9.3 1.3 0L5 6.9l2.8 2.8c.3.3.9.3 1.3 0l.6-.6c.3-.3.3-.9 0-1.3L6.9 5z"
                    />
                  </svg>
                </span>
                <span class="d-block s-table__btn-text" @click="removeReservation"> Odstranit rezervaci </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ReservationModel } from '../../common/models/reservation.model';

import { XiteApiService } from '../../services/xite.api.service';

import moment from 'moment/min/moment-with-locales';

export default {
  name: 'ReservationsManagement',

  computed: {
    ...mapGetters({
      localization: 'languages/getBrowserLanguage',
      reservations: 'reservations/getAllReservations',
      me: 'me/getMe',
    }),
  },

  watch: {
    me: {
      handler() {
        if (this.me) {
          this.getReservations();
        }
      },
    },
  },

  methods: {
    ...mapActions('reservations', ['setReservations']),
    ...mapActions('me', ['getMeData']),

    getReservationCustomerNames(reservation) {
      return reservation.customers.map(customer => `${customer.name} ${customer.surname[0]}.`).join(', ');
    },

    getReservations() {
      XiteApiService.get('Reservations', {
        customer: this.me.attributes.id,
      }).then(file => {
        this.setReservations(file.reservation.map(reservation => new ReservationModel(reservation)));
      });
    },

    removeReservation() {
      console.log('removeReservation');
    },

    editReservation() {
      console.log('editReservation');
    },
  },

  created() {
    moment.locale('cs');
    if (this.me) {
      this.getReservations();

      if (this.$route.params.reloadCustomer) {
        this.getMeData(this.me.attributes.id);
      }
    }
  },
};
</script>
