import { Model } from '../parents/model';
import moment from 'moment/min/moment-with-locales';

export class ReservationModel extends Model {
  static ATTR_ID = 'id';
  static ATTR_DATE = 'date';
  static ATTR_TIME = 'time';
  static ATTR_LOCATION = 'location';
  static ATTR_INTERVENTION = 'intervention';
  static ATTR_INTERVENTION_METHOD = 'intervention_method';
  static ATTR_PRICE = 'price';
  static ATTR_CUSTOMERS = 'customers';

  get dateTime() {
    return `${moment(this.attributes[ReservationModel.ATTR_DATE][0]).format('DD.MM.YYYY')} ${
      this.attributes[ReservationModel.ATTR_TIME][0]
    }`;
  }

  /*get location() {
    return this.attributes[ReservationModel.ATTR_LOCATION];
  }

  get intervention() {
    return this.attributes[ReservationModel.ATTR_INTERVENTION];
  }

  get interventionMethod() {
    return this.attributes[ReservationModel.ATTR_INTERVENTION_METHOD];
  }

  get price() {
    return this.attributes[ReservationModel.ATTR_PRICE];
  }

  get customers() {
    return this.attributes[ReservationModel.ATTR_CUSTOMERS].map(customer => new CustomerModel(customer));
  }*/

  getTimeSpanString(localization) {
    const date = new Date(this.attributes[ReservationModel.ATTR_DATE]);
    const startTime = date.toLocaleTimeString(localization, { hour: '2-digit', minute: '2-digit' });
    const endTime = new Date(date.getTime() + 15 * 60000).toLocaleTimeString(localization, {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `od ${startTime} do ${endTime}`;
  }

  getDateString(localization) {
    const date = this.attributes[ReservationModel.ATTR_DATE];
    return new Date(date).toLocaleDateString(localization, { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
}
